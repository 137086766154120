/* auth.css */
.logo {
  width: 100%;
  max-width: 100px;
}

.form-wrapper {
  position: relative;
  height: 100vh;

  .form-main {
    width: calc(100% - 96px);
    max-width: 450px;
    margin: 0 auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 40px;
    border-radius: 10px;
    background: #fff;
    -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);

    @media only screen and (max-width: 767px) {
      // width: calc(100% - 30px);
      // max-width: inherit;
      margin: 0 20px;
      padding: 1.2rem;
      left: 45%;
    }

    .form-head {
      text-align: center;

      h4 {
        font-size: 20px;
        font-weight: $weight-semi;
        margin-bottom: 15px;

        // @media only screen and (max-width: 767px) {
        //     padding-bottom: 20px;
        //     max-width: 200px;
        //     width: 100%;
        //     margin: 0 auto;
        //     line-height: 33px;
        //     font-size: 26px;
        // }
      }
    }
  }
}

label.ant-form-item-required,
label {
  color: $labelColor !important;
  font-size: 14px !important;
  font-weight: $weight-medium;
}

.ant-select-selector {
  text-align: start;
}
.ant-input-number-group-wrapper{
  .ant-input-number{
    outline: none;
    border: 1px solid $inputBorderHoverColor !important;
    box-shadow: none !important;
    .ant-input-number-input{
      width: 100%;
      min-height: 36px;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: #000;

      &:focus,
      &:hover {
        outline: none;
        border: 0px solid $inputBorderHoverColor;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    &:focus,
    &:hover {
      outline: none;
      border: 1px solid $inputBorderHoverColor;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
  .ant-input-number-group-addon{
    border-color: $inputBorderHoverColor !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }
}
.ant-input,
.ant-picker {
  // background-color: $inputBg;
  border-radius: 6px;
  border: 1px solid $inputBorderColor;
  width: 100%;
  min-height: 38px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #000;

  // @media only screen and (max-width: 767px) {
  //     min-height: 32px;
  // }

  &:focus,
  &:hover {
    outline: none;
    border: 1px solid $inputBorderHoverColor;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: none;
  border-color: transparent;
}
.ant-picker-focused {
  border-color: $inputBorderHoverColor !important;
  box-shadow: none !important;
}

.ant-select-focused {
  box-shadow: none !important;
}

.login-form-forgot {
  text-align: right;
  margin-bottom: 0;

  & a {
    font-size: 14px;
    font-weight: $weight-medium;
    text-decoration: none;
    color: $primary;

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.ant-input-status-error {
  border: 1px solid $inputBorderColor !important;

  &:focus,
  &:hover {
    outline: none;
    border-color: $inputBorderHoverColor !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.login-form-button,
.signin-form-button,
.forgot-button {
  background-color: $primary;
  border-radius: 8px;
  width: 100%;
  min-height: 35px;
  line-height: 1;
  padding: 0.375rem 1.75rem;

  & span {
    color: $white;
    font-size: 14px;
    font-weight: $weight-medium;

    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &:hover {
    background-color: $primary !important;
  }
}

.ant-select {
  // background-color: $inputBg;
  border-radius: 6px;
  border: 1px solid $inputBorderColor;
  width: 100%;
  min-height: 36px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &.ant-select-single {
    min-height: 38px;
  }

  .ant-select-selector {
    min-height: 36px;
    border: none !important;
    border-radius: 6px !important;
    box-shadow: none !important;
  }

  &:focus,
  &:hover {
    outline: none;
    border: 1px solid $inputBorderHoverColor;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.ant-input-group-wrapper {
  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;

      .ant-input,
      .ant-picker,
      .ant-select {
        min-height: 36px;
      }
    }

    .ant-input-group-addon {
      .ant-btn {
        min-height: 38px;
      }
    }
  }
}

.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;

      .ant-input,
      .ant-picker,
      .ant-select {
        min-height: 36px;
        padding: 4px 11px;
        border-radius: 6px;
      }

      &:focus,
      &:hover,
      &:focus-within {
        outline: none;
        border: 1px solid $inputBorderHoverColor;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
  }
}
