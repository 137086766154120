.card{
    width: 100%;
    // max-width: 500px;
    background-color: $white;
    border: 1px solid $borders;
    padding: 5px 0;
    box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.1);
    .card-header{
        background-color: transparent;
        border-bottom: none;
        .card-left{
            span.ant-checkbox-checked{
                background-color: $sideBarBg !important;
                color: $black;
            }
            span.ant-checkbox-inner{
                // background-color: $sideBarBg;
                border: 1px solid $labelColor;
                color: $black;
                padding: 10px;
                &:after{
                    inset-inline-start: 30%;
                }
            }
            h4{
                font-weight: $weight-medium;
                padding-left: 20px;
                margin-bottom: 0;
                font-size: 18px;
            }
        }
        .card-right{
            .tag{
                background-color: $tagColor;
                padding: 8px 2px 2px 2px;
                margin-left: 20px;
                span{
                    font-size: 16px;
                    font-weight: $weight-regular;
                }
            }
            .anticon-eye{
                cursor: pointer;
            }
        }
    }
    .card-content{
        padding: 0 20px;
        @media (max-width: 767px) {
            padding: 0 10px;
        }
        .card-title, .card-subtitle{
           color: $black;
           font-size: 14px;
        //    padding: 10px 0;
        }
        .card-title{
            font-weight: $weight-regular;
            line-height: 2;
        }
        .card-subtitle, .card-subtitleyellow{
            font-weight: $weight-semi;
            padding-left: 5px;
        }
        .card-subtitleyellow{
            color: $tagColor;
        }
    }
    table{
        border-radius: 8px;
        th{
            border: none !important;
        }
        tbody{
            background-color: transparent;
            
            td,tr{
                border: none !important;
            }
        }
    }
    .lead-card-exchange-tag{
        margin: 0 0 0 auto;
        &.ant-tag-gold{
            font-weight: 500;
            color: #A36802;
            background: #fffbe6;
            border-color: #EA9A12;
        }
    }
}
.card-lead{
    .lead-status-badge{
        .ant-badge-count{
            color: #000;
            background-color: #f9b73f;
            font-weight: 600;
            text-transform: uppercase;
        }
        &.open, &.Open{
            .ant-badge-count{
                color: #000;
                background-color: #f9b73f;
            }
        }
        &.lost, &.Lost{
            .ant-badge-count{
                color: #FFF;
                background-color: #FF0000;
            }
        }
        &.converted, &.Converted{
            .ant-badge-count{
                color: #000;
                background-color: #52B300;
            }
        }
        &.dropped, &.Dropped{
            .ant-badge-count{
                color: #FFF;
                background-color: #FF5722;
            }
        }
    }
    @media (max-width: 1250px) and (min-width:1024px) {
        .card-header{
            .card-right{
                // position: absolute;
                // right: 10px;
            }
        }
    }
    
    @media (max-width: 767px) {
        .card-header{
            padding-top: 1.3rem !important;
            .card-tag{
                position: absolute;
                right: 1.25rem;
                top: 5px;
            }
        }
    }
}

.card-header.d-flex.justify-content-start.align-items-baseline.w-100 {
    padding: 0px 1.25rem 1rem 1.25rem;
}

.quotation-card{
    .card-header{
        padding: 0.75rem 1.25rem;
        h4{
            font-weight: $weight-semi !important;
            padding-left: 0px !important;
        }
    }
}