.user-data {
    // background-color: $white;
    padding: 1rem 2rem;

    @media (max-width: 767px) {
        padding: 1rem 0.87rem;
    }

    h1.page-head {
        font-size: 20px;
        font-weight: $weight-medium;
        text-align: left;
    }

    .sso-select {
        gap: 10px;
    }

    .user-table-head {
        @media (max-width: 767px) {
            display: block !important;
        }

        .user-left {
            h1 {
                font-size: 28px;
                font-weight: $weight-medium;
            }

            @media (max-width: 767px) {
                // padding-bottom: 2rem;
            }
        }
    }

    .dash-table-head {
        @media (max-width: 767px) {
            display: flex !important;
        }
    }

    .user-left {
        h4 {
            font-size: 20px;
            font-weight: $weight-semi;
        }

        h5 {
            font-size: 18px;
            font-weight: $weight-bold;
        }
    }

    .detail-wrapper {
        .login-input-field {
            // margin-right: 2rem;
        }
    }

    .ant-switch.ant-switch-checked {
        background-color: $primary !important;
    }

    .lead-searchbtn {
        width: 100%;
        text-align: left;
    }

    .user-tags {
        font-size: 14px;
        font-weight: $weight-medium;
        color: $labelColor;
        border-radius: 100px;
        padding: 3px 26px 5px 12px;

        .anticon-close {
            font-size: 10px;
            color: $black;
            position: absolute;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
        }
    }

    .view-booking {
        .view-fields {
            // margin: 20px 0 !important;
            padding: 10px 0 !important;
            margin: 0 !important;
        }
    }

    .view-fields {
        margin: 10px 0;

        h6 {
            position: relative;
            font-size: 14px !important;
            font-weight: 600;
            min-width: 140px;
            padding-right: 16px;
            text-align: left;

            // @media (max-width: 1200px) {
            //     width: 30%;
            // }
            .asterisk{
                position: absolute;
                right: auto;
                margin-left: 4px;
            }
        }

        p {
            text-align: left;
            word-wrap: break-word;
            word-break: break-word;
        }

        .value,
        .note-box {
            text-align: left;
            width: 60%;
        }

        .note-box {
            @media (max-width:768px) {
                width: 100%;
            }
        }

    }

    .lead-stage {
        h6 {
            text-align: left;
            padding-right: 10px;
            font-weight: 600;
        }

        @media (max-width:700px) {
            display: block !important;

            h6 {
                width: 40% !important;
                margin: 10px 0;
            }
        }

        // .car-varient{
        //     width: 200px;
        // }
        .model-name-wrapper {
            margin-top: -20px;
        }
    }

    .lead-stage-top {
        h6 {
            min-width: 0 !important;
            font-size: 16px !important;
        }
    }

    .target-datepicker,
    .add-enquiry-icon {
        padding-top: 1.8rem;
    }

    .lead-funel-stage-wrapper,
    .lead-funel-type-wrapper {
        // width: 200px;
        // height: 200px;
    }
}

.transfer-lead {
    text-align: end;

    button {
        background-color: transparent;
        color: $black;
        border: none;
        box-shadow: none;
        font-size: 18px;
        font-weight: $weight-medium;

        &:hover {
            background-color: transparent !important;
            color: $black !important;
        }
    }

    .upload-lead {
        padding-right: 2rem;
    }
}

.leads-btn {
    // @media (max-width:408px) {
    //     flex-direction: column;
    //     gap: 15px;
    // }
    // .secondary-button , a{
    //     @media (max-width:408px) {
    //         margin: 0 !important;
    //         width: 100%;
    //     }
    // }
}

//activity log
.activity-log {

    p,
    .subtitle,
    span {
        font-size: 18px;
        font-weight: $weight-medium;
        color: $black;

        @media (max-width:767px) {
            font-size: 14px;
        }
    }

    .subtitle {
        color: $primary;
    }

    span {
        font-size: 14px;
        font-weight: $weight-regular;
        color: $inputColor;
    }

    span.ant-steps-icon {
        background-color: #eee;
        padding: 10px;
        border-radius: 50%;
        display: flex;

        span {
            color: #000;
        }
    }

    .sub-subtitle {
        font-size: 14px;
        font-weight: $weight-regular;
        color: $black;

        span {
            font-size: 14px;
            font-weight: $weight-regular;
            display: inline-block;
        }

        .active-log-label {
            font-size: 14px;
            font-weight: $weight-medium;
            color: $black;
            min-width: 60px;
            padding-right: 10px;
        }
    }
}

// .lead-label {
//     .ant-row {
//         flex-direction: row;
//         justify-content: start;
//         align-items: center;

//         .ant-form-item-label {
//             // width: 6% !important;
//         }

//         .ant-form-item-control {
//             width: 80%;

//             .ant-form-item-control-input {
//                 width: 20% !important;
//             }
//         }
//     }
// }



//button with icon
.btn-with-icon {
    @media (max-width:400px) {
        .page-content-header-right {
            .ant-space {
                flex-direction: column;
                gap: 10px;
                width: 100%;

                .ant-space-item {
                    .secondary-button {
                        width: 100%;
                    }

                    width: 100% !important;
                    display: block;
                }
            }

        }
    }

}

//view-leads tabs
.lead-detail {
    padding: 0;

    .view-fields,
    .price-detail-wrap {
        @media (max-width:400px) {
            display: block !important;

            h6 {
                width: 100% !important;
            }
        }

        h6 {
            width: 32%;
            position: relative;
            padding-right: 16px !important;
            .asterisk{
                position: absolute;
                right: auto;
                margin-left: 4px;
            }
            & + p {
                width: 68%;
            }
            @media (max-width:768px) {
                width: calc(100% - 50%);
            }
        }

        .lead-input,
        .lead-input-wrapper {
            width: 50% !important;

            @media (max-width:992px) {
                width: 100% !important;
            }
        }

        .lead-input-wrapper {
            .ant-form-item {
                margin-bottom: 0 !important;
            }
        }
    }
}

.lead-form-label {
    @media (max-width:767px) {
        display: block;
    }

    h6 {
        position: relative;
        width: 20%;
        @media (max-width:767px) {
            width: 40%;
        }
        .asterisk{
            position: absolute;
        }
    }

    .value {
        width: 40%;

        @media (max-width:767px) {
            width: 100%;
        }

        .ant-image {
            border: 1px solid #e9f0ee;
            padding: 3px;

            img {
                width: 100%;
                height: 100% !important;
            }
        }
    }
    &.picture-uploaded{
        align-items: flex-start !important;
        @media (min-width:768px) {
            h6{
                padding-top: 0px;
            }
        }
    }
}

.feedbacks {
    h6 {
        width: 10% !important;

        @media (max-width:767px) {
            width: 100% !important;
        }
    }
}

.price-detail-wrap {
    @media (max-width:767px) {
        display: block !important;
    }

    h6 {
        width: 40%;

        @media (max-width:767px) {
            width: 100%;
        }
    }
}

.pricing-input {
    .ant-form-item {
        width: 250px;
    }
}

.exchange-upload
// .exchange-upload-wrapper 
{
    // padding-top: 10px;

    p.label {
        font-size: 14px !important;
        font-weight: 600;
        text-align: left;
        width: 180px;
        padding-top: 8px;
    }

    // padding-bottom: 1.5rem;
    // p {
    //     width: 280px;
    // }

    span.ant-upload-wrapper {
        text-align: left;
        width: calc(100% - 180px) !important;
        // display: flex;

        @media screen and (max-width:567px) {
            display: block;
        }

        .ant-upload-select {
            display: inline-block;
            width: 38px;
        }
    }

    // @media screen and (max-width:767px) {
    //     p{
    //         width: 30%;
    //     }
    // }
    .ant-upload-list.ant-upload-list-text {
        // position: absolute;
        // left: 0;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: normal;
        // width: calc(100% - 38px);
        // margin-left: 1rem;

        @media screen and (max-width:567px) {
            position: relative !important;
            left: -180px;
            width: calc(100% + 180px);
            margin-left: 0;
        }

        .ant-upload-list-item-container {
            // display: inline-block;

            .ant-upload-list-item.ant-upload-list-item-done {
                // width: 70%; 
                // position: inherit !important;
                background-color: #f5f5f5;
                padding: 4px;
                border-radius: 3px;
                margin-bottom: 8px;

                @media screen and (max-width:567px) {
                    margin: 3px 0 10px 0;
                }
            }
        }
    }

    button.ant-upload-list-item-action {
        opacity: 1 !important;
        // display: none !important;
        // &:hover{
        //     display: none !important;  
        // }
    }
}

// .exchange-upload-wrapper {
//     span.ant-upload-wrapper {
//         width: calc(100% - 240px) !important;
//     }
// }

.lead-stage {
    .anticon-edit {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 10px;
    }
}

.view-field-quot {
    .value {
        margin-left: auto;
        width: 100%;
        max-width: 220px;
        text-align: right !important;

        @media (max-width:767px) {}
    }
}