.ant-modal-content, .ant-modal-header{
    background-color: $sideBarBg !important;
    .modal-head{
        border-bottom: 1px solid $borders;
        padding-bottom: 1rem;
        position: relative;
        h5{
            font-size: 20px;
            font-weight: $weight-medium;
            line-height: 1.3;
        }
        p{
            font-size: 16px;
            font-weight: 400;
        }
        .lead-selected-count{
            position: absolute;
            top: 3px;
            left: 0;
        }
        @media screen and (max-width: 991px) {
            .lead-selected-count{
                position: static;
            }
        }
    }
    .modal-footer{
        border-top: 1px solid $borders;
        padding-top: 1rem;
    }
    
}

.lead-modal{
    width: calc(100% - 30px) !important;
    max-width: 950px;
    margin: 0 auto;
}

.ant-popconfirm-buttons{
    button.ant-btn-primary{
        background-color: $primary;
        &:hover{
            background-color: $primary !important;  
        }
    }
    button.ant-btn-default{
        color: #ff4d4f;
        background: transparent;
        border-color: #ff4d4f;
        box-shadow: none;
        &:hover{
            color: #ff4d4f !important;
            background: transparent;
            border-color: #ff4d4f !important;
            box-shadow: none;
        }
    }
}