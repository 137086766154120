.sidebar,
.ant-layout-sider {
    background-color: $sideBarBg !important;
    min-height: calc(100vh - 54px);

    li.ant-menu-item, .ant-menu-submenu-title  {
        font-size: 16px;
        font-weight: $weight-medium;
        color: $labelColor !important;
        border: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0.875rem 1.5rem;
        height: auto !important;
        line-height: 1.3 !important;
        &:hover {
            background-color: $menuActive;
            color: $primary;
        }
    }
    .ant-menu-item-selected, .ant-menu-item-active{
        background-color: $menuActive !important;
        color: $primary !important;
    }
    .ant-menu-submenu{
        .ant-menu-sub{ 
            li.ant-menu-item{
                padding: 0.475rem 1.5rem;
                font-size: 14px;
            }
        }
        &.ant-menu-submenu-selected{
            .ant-menu-submenu-title{
                background-color: $menuActive;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .custom-sidebar{
        &.hide{
            left: -225px;
        }
        &.show{
            left: 0;
        }
    }
    .ant-layout-sider.custom-sidebar{
        position: fixed;
        top: 54px;
        bottom: 0;
        /* left: 0; */
        z-index: 9999;
        overflow: auto;
    }
    .custom-sidebar.ant-layout-sider-collapsed{
        overflow: hidden;
        flex: 0 0 225px !important;
        max-width: 225px !important;
        min-width: 225px !important;
        width: 225px !important;
        left: -225px;
    }
}