//Font Weight
$weight-bold: bold;
$weight-semi: 600;
$weight-medium: 500;
$weight-normal: 300;
$weight-regular: 400;
$weight-light: 100;

//FOnt-color
$white: #fff;
$black: #000;
$labelColor: #051A1A;
$inputColor: #898989;
$inputBorderColor: #D9d9d9;
$inputBorderHoverColor: #d9d9d9;
$inputBg: #F3F5F9;
$error: #CC0000;
$tagColor: #f9b73f;
$borders: #e4e4e4;
$sideBarBg: #F8F8F8;
$menuActive: #e0f1ce;
$primary: #0a9551;
$sideBarHover: #E9D7D8;
$sideBarButton: #D6DAE3;
$innerBorder: #DBDBDB;
$dashboardHeadingColor: #515C6F;
$siteBg: #4DAA40;
$paymentCard: #F8F8F8;
$tableBorder: #E6E6E6;
$tableTh: #A3A9B4;
$targetHeadColor: #72C382;
$achievedHeadColor: #E36664;
$targetHeadBg: #C8DEC5;
$achievedHeadBg: #F0A09A;



$font-family: "Montserrat", sans-serif;
// $font-light: "Montserrat", sans-serif;
// $font-regular:"Montserrat", sans-serif;
// $font-medium: "Montserrat", sans-serif;
// $font-bold: "Montserrat", sans-serif;
// $font-black: "Montserrat", sans-serif;

//justify content
$justify-content-values: (
    start: flex-start,
    end: flex-end,
    center: center,
    between: space-between,
    around: space-around,
    evenly: space-evenly
);

@mixin generate-justify-content() {
    @each $key, $value in $justify-content-values {
        .justify-content-#{$key} {
            justify-content: $value !important;
        }
    }
}

@include generate-justify-content();

//margin spacing
$margin-values: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

$sides: (
  t: top,
  r: right,
  b: bottom,
  l: left,
  x: left right, // for horizontal margins
  y: top bottom, // for vertical margins
  s: start,      // for margin-start
  e: end         // for margin-end
);

@mixin generate-margins() {
  @each $key, $value in $margin-values {
    @each $abbr, $side in $sides {
      @if $abbr == x {
        .m#{$abbr}-#{$key} {
          margin-left: $value !important;
          margin-right: $value !important;
        }
      } @else if $abbr == y {
        .m#{$abbr}-#{$key} {
          margin-top: $value !important;
          margin-bottom: $value !important;
        }
      } @else if $abbr == s {
        .m#{$abbr}-#{$key} {
          margin-inline-start: $value !important;
        }
      } @else if $abbr == e {
        .m#{$abbr}-#{$key} {
          margin-inline-end: $value !important;
        }
      } @else {
        .m#{$abbr}-#{$key} {
          margin-#{$side}: $value !important;
        }
      }
    }
  }
}

@include generate-margins();

//padding spacing
$padding-values: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

$sides: (
  t: top,
  // r: right,
  e: right,
  b: bottom,
  // l: left,
  s: left,
  x: left right, // for horizontal padding
  y: top bottom  // for vertical padding
);

@mixin generate-paddings() {
  @each $key, $value in $padding-values {
    .p-#{$key} {
      padding: $value !important;
    }

    @each $abbr, $side in $sides {
      @if $abbr == x {
        .p#{$abbr}-#{$key} {
          padding-left: $value !important;
          padding-right: $value !important;
        }
      } @else if $abbr == y {
        .p#{$abbr}-#{$key} {
          padding-top: $value !important;
          padding-bottom: $value !important;
        }
      } @else {
        .p#{$abbr}-#{$key} {
          padding-#{$side}: $value !important;
        }
      }
    }
  }
}

@include generate-paddings();


//alignment
@mixin align-items($alignment) {
    align-items: $alignment !important;
  }
  
  .align-items {
    &-baseline {
      @include align-items(baseline);
    }
  
    &-center {
      @include align-items(center);
    }
  
    &-top {
      @include align-items(flex-start);
    }
  }

  //gap
  $gap-values: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

@mixin generate-gaps() {
  @each $key, $value in $gap-values {
    .gap-#{$key} {
      gap: $value !important;
    }

    .row-gap-#{$key} {
      row-gap: $value !important;
    }

    .column-gap-#{$key} {
      column-gap: $value !important;
    }
  }
}

@include generate-gaps();
