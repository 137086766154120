#root {
  height: 100%;
  width: 100%;
  /* background-color: rgb(239, 241, 242); */
  background-color: #e9f0ee;
  overflow-wrap: break-word;
  font-family: 'Montserrat', sans-serif !important;
}
html, body{
  margin: 0;
  padding: 0;
}
html{
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}
body,
button,
a,
label {
  font-family: 'Montserrat', sans-serif !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
.ant-col,
div {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif !important;
}

.text-danger{
  color: $error !important;
}
//word break start
.word-break-break-all {
  word-break: break-all;
}

//word break end

// menu-trigger-button starts
.menu-trigger-button {
  position: absolute;
  left: 11.5rem;
}

.menu-trigger-button.m-hide {
  top: 0.7rem;
}

.menu-trigger-button.d-hide {
  top: 1.35rem;

  @media (max-width:1024px) and (min-width:767px) {
    top: 0.7rem;
  }
}

//menu menu-trigger-button ends
.ant-form-item{
  .ant-form-item-control-input{
    min-height: 38px;
  }
}
//error message starts
.ant-form-item-explain{
  padding: 0 0 5px 0 !important;
}
.ant-form-item-explain-error {
  &:empty{
    display: none !important;
    padding: 0 !important;
  }
  font-size: 12px;
  padding: 0 !important;
}

//error message ends

// button start
.otp-btn {
  margin-top: 1.87rem;

  @media (max-width: 767px) {
    margin-top: 0px;
    float: inline-end;
  }
}

.secondary-button,
.primary-button {
  border-radius: 5px;
  min-height: 35px;
  font-weight: $weight-semi;
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  // flex-grow: 1;
}

.secondary-button {
  background-color: $primary;

  &:hover {
    background-color: $primary !important;
  }

  &:disabled {
    background-color: #f5f5f5 !important;
  }
}

.primary-button {
  background-color: $black;

  &:hover {
    background-color: $black !important;
  }

  &.ant-btn-dangerous:hover {
    background-color: transparent !important;
  }
}

.ant-upload {
  .ant-btn {
    border-radius: 8px;
    border: 1px solid $inputBorderColor;
    width: 100%;
    min-height: 38px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 6px 10px 10px 10px;

    &:hover {
      outline: none;
      border: 1px solid $primary !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;

      .anticon {
        color: $primary;
      }
    }

    &:disabled {
      cursor: not-allowed;

      &:hover {
        border-color: #D9d9d9 !important;

        .anticon {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.ant-btn {
  &.ant-btn-circle.ant-btn-default.ant-btn-icon-only {
    &:hover {
      color: $primary;
      border-color: $primary;

      .anticon {
        color: $primary;
      }
    }
  }
}

// button end

// table start
table {
  border: 1px solid $tableBorder;
  border-radius: 8px;
  font-weight: $weight-bold;

  th,
  td {
    border-right: 1px solid $tableBorder;
    font-size: 14px;
    padding: 10px 14px !important;
    min-height: 50px !important;
    font-weight: $weight-regular;
  }

  .ant-table-measure-row {

    th,
    td {
      min-height: 0 !important;
      padding: 0 !important;
    }
  }
}

th.ant-table-cell {
  background-color: $sideBarBg !important;
  font-weight: $weight-medium;
}

.responsive-table-container {
  overflow-x: auto;
}

.ant-picker-dropdown {
  table.ant-picker-content {
    border: none;

    th,
    td {
      border-right: none;

      .ant-picker-cell-inner {
        &:before {
          border: 1px solid $primary !important;
        }
      }
    }

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background-color: $primary !important;

        &:hover {
          background-color: $primary !important;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .ant-picker-panel-container{
      .ant-picker-panel-layout{
        .ant-picker-panel{
          .ant-picker-time-panel{
            .ant-picker-content{
              .ant-picker-time-panel-column{
                overflow-y: scroll !important;
                width: 70px;
                &:after{
                  display: initial;
                }
                .ant-picker-time-panel-cell{
                  .ant-picker-time-panel-cell-inner{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// table end

// pagination start
ul.ant-pagination {
  align-items: center;
  li.ant-pagination-prev,
  li.ant-pagination-next {
    color: $primary;
  }

  li.ant-pagination-item-active {
    color: $primary;
    border-color: $primary;
    border-radius: 50%;
    &:hover {
      color: $primary;
      border-color: $primary;
      a{
        color: $primary;
      }
    }

    a {
      color: $primary;
    }
  }
  /* Disableed the searchable option from ant design table pagination Show Size Changer */
  .ant-pagination-options{
    .ant-select{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: inline-block;
        width: 8px;
        height: 8px;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        transform: translateY(-50%) rotate(135deg);
        margin-top: -2px;
      }
      .ant-select-selector{
        cursor: pointer !important;
        .ant-select-selection-search{
          display: none !important;
        }
      }
      .ant-select-arrow{
        opacity: 0 !important;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
      min-width: 28px;
      height: 28px;
      line-height: 27px;
      margin-inline-end: 4px;
    }
  }
}

.ant-table-pagination-right {
  justify-content: center !important;
}

// pagination end

//ant switch start
.ant-switch-inner {
  padding: 2px;
}

//ant switch end

//ant icons start
.anticon-eye,
.anticon-delete,
.anticon-plus-circle,
.anticon-minus-circle,
.anticon-edit {
  color: $black;
  cursor: pointer;
  font-size: 20px;
}

.anticon-edit {
  color: $black;
  font-size: 20px;
}

.personal-detail {

  .anticon-eye,
  .anticon-delete,
  .anticon-plus-circle,
  .anticon-minus-circle,
  .anticon-edit {
    cursor: inherit;
  }
}

//ant icons end

//tab start
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: $primary;
}

.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: $weight-medium;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary !important;
}

.ant-tabs-tab-btn,
.ant-tabs-tab {
  &:hover {
    color: $black !important;
  }
}

.ant-tabs-nav {
  &::before {
    display: none;
  }
}

//tab end

//
.ant-form-item-required {
  &::before {
    display: none !important;
  }

  &::after {
    position: absolute !important;
    content: '*' !important;
    color: red;
    right: -18px;
    font-size: 12px;
    font-weight: 600;
    visibility: visible !important;
  }
}

.asterisk {
  color: #ff0000;
}

.h-line {
  border-bottom: 1px solid $borders;
  height: 1px;
  margin: 0 0 20px 0;
  padding: 0;
}

.form-item-horizontal {
  margin-bottom: 10px;

  .ant-row {
    display: flex;
    flex-direction: row;

    .ant-form-item-label {
      width: 50%;
      padding-top: 5px;
    }

    .ant-form-item-control {
      width: 50%;
    }
  }

  @media screen and (max-width: 1200px) {
    .ant-row {
      flex-direction: column;

      .ant-form-item-label {
        width: 100%;
      }

      .ant-form-item-control {
        width: 100%;
      }
    }
  }
}

.form-item-inline {
  margin-bottom: 10px;

  .ant-row {
    display: flex;
    flex-direction: row;

    .ant-form-item-label {
      width: auto;
      padding-top: 7px;
      padding-right: 34px;
    }

    .ant-form-item-control {
      width: 50%;
    }
  }
}

// .add-lead-form {
//   .ant-form-item-label {
//     width: 30% !important;
//   }
// }

svg>g>g.google-visualization-tooltip {
  pointer-events: none;
}

svg>g:last-child>g:last-child {
  pointer-events: none;
}

div.google-visualization-tooltip {
  pointer-events: none;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  height: auto !important;
  // box-shadow: unset !important;
  // background-color: rgba(0,0,0,0) !important;
  // overflow:hidden !important;
}

.filter-card {
  .ant-card-body {
    padding: 20px 24px 24px 24px;
  }
  .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    .ant-card-body {
      padding: 14px 16px 16px 16px;
    }
  }
}

.layout-with-sidebar {
  background-color: #ffffff;
}
.uploaded-img{
  width: 100px;
  height: 100px;
  display: block;
  overflow: hidden;
  border: 1px solid #e9f0ee;
  padding: 3px;

}

.uploaded-img {
  border: 1px solid #e9f0ee;
  padding: 3px;
  width: 100px;
  height: 100px;
  display: block;
  overflow: hidden;
}

.profile-image-box {
  width: 150px;
  height: 150px;
  display: block;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid $inputBorderColor;

  .anticon-eye {
    color: #ffffff;
    font-size: 14px;
  }
}

.lead-type-select,
.lead-status-select {
  min-width: 110px;
}

.exchange-form-detail{
  .ant-form-item{
    .ant-form-item-control-input{
      align-items: flex-start;
      padding-top: 5px;
    }
  }
}

@media screen and (min-width: 1024.5px) {
  .d-hide {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .m-hide {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels{
    flex-direction: column;
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels{
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panel .ant-picker-datetime-panel{
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
  }
}

//step bar icons
.ant-steps-item-title {
  width: auto;

  // @media (max-width: 767px) {
  //   width: 25%;
  // }
}

.ant-steps-item-description {
  flex-grow: 1;
}

.ant-steps-item-content {
  display: flex !important;
  align-items: flex-start;
  min-height: 100px;
  padding-bottom: 10px;
}

span.ant-select-selection-placeholder {
  color: rgb(0 0 0 / 63%) !important;
}
.danger-text{
  color: #ff4d4f;
  &:hover{
    color: #ff7875 !important;
  }
}

.ant-modal{
  .ant-btn-default{
    color: #ff4d4f;
    background: transparent;
    border-color: #ff4d4f;
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
    &:hover{
      background: transparent;
      color: #ff7875 !important;
      border-color: #ff7875 !important;
    }
  }
  .ant-btn-primary{
    background-color: $primary !important;
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: $primary;

    &:hover {
      background-color: $primary !important;
    }
  }

  .ant-btn-default {
    &:hover {
      border-color: #d9d9d9 !important;
      color: #000 !important;
    }
  }
}

.ant-input-outlined {

  &:hover,
  &:focus-within {
    border-color: $inputBorderHoverColor;
    box-shadow: none !important;
  }
}

.ant-tabs-nav-wrap {

  &:hover,
  &:after {
    box-shadow: none !important;
  }
}

//exchange-text hide starts
@media screen and (min-width: 768px) {
  .d-sm-hide {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .m-sm-hide {
    display: none;
  }
}

//exchange-text hide ends

.ant-notification-notice-wrapper {
  .ant-notification-notice {
    padding: 10px 10px 10px 18px !important;

    .ant-notification-notice-close {
      top: 10px !important;
      inset-inline-end: 15px !important;
    }
  }

  .ant-notification-notice-closable {
    .ant-notification-notice-message {
      padding-inline-end: 0px !important;
    }
  }

  .ant-notification-notice-with-icon {
    display: flex;
    align-items: baseline;

    .ant-notification-notice-message {
      font-size: 14px !important;
      font-weight: $weight-regular;
      margin-bottom: 5px !important;
    }

    .ant-notification-notice-description {
      margin-inline-start: 0px !important;
      margin-inline-end: 24px !important;

      @media screen and (max-width:767px) {
        padding-right: 12px !important;
      }
    }

    .ant-notification-notice-icon-error {
      .ant-notification-notice-closable {
        .ant-notification-notice-message {
          padding-inline-end: 0px !important;
        }
      }

      .ant-notification-notice-description {
        margin-inline-start: 0px !important;
        margin-inline-end: 20px !important;

        @media screen and (max-width:767px) {
          padding-right: 10px !important;
        }
      }
    }
  }
}

//side modal starts
.side-modal {
  .ant-form-item-label {
    width: 50% !important;
  }

  label {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    &::after {
      display: none;
    }

    span {
      padding-inline-start: 0px;
    }
  }

  .ant-checkbox-group {
    display: block !important;
  }
}

.image-upload {
  .ant-row {
    width: 100% !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  label {
    font-size: 14px !important;
    font-weight: 600;
    text-align: left;
  }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner {
  background-color: rgb(75, 141, 233) !important;
  border-color: rgb(75, 141, 233) !important;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #e6f7f3 !important;
}
.select-month{
  min-width: 140px;
}
//picker starts
.ant-picker-range .ant-picker-active-bar{
  background-color: $primary !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
  background-color: #d7ffe4;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
  background-color: #3dc969 !important;
}
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after, .ant-picker-dropdown .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after{
  background-color: #3dc969 !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background-color: $primary !important;
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
  border-color: $primary !important;
}

.ant-picker-range-separator{
  .ant-picker-separator{
    .anticon{
      vertical-align: 1px !important;
    }
  }
}

.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background-color: #cde0d6 !important;
  }
}

.ant-picker-now-btn {
  color: $primary !important;
}

li.ant-picker-ok {
  .ant-btn-primary {
    background-color: $primary !important;
    color: $white !important;

    &:disabled {
      background-color: $inputBorderColor !important;
      color: $white !important;
    }
  }
}

#error-page {
  .personal-detail {
    height: 100vh;

    .ant-result {
      padding: 0;
    }
  }
}

.no-record {
  margin: 0 auto;
}

.listing{
  ul{
    padding-left: 20px;
  }
}

.card-cursour{
  .card-right, .badge{
    cursor: pointer;
  }
}
// .exchange-horizontal-label {
//   .form-item-horizontal {
//     .ant-row .ant-form-item-label {
//       width: 30%;
//       padding-top: 5px;
//     }
//   }
// }

//custom scss starts
.ms-auto {
  margin-left: auto;
}

.pe-0 {
  padding-right: 0px !important;
}

.me-sm-0{
  margin-right: 0px !important;
}

.mt-xs-3{
  margin-top: 1rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-flex {
  display: flex;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.inline-block{
  display: inline-block;
}

.w-100 {
  width: 100%;
}

.w-100px {
  min-width: 280px;
}

.h-100 {
  height: 100% !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.white-space-normal{
  white-space: normal !important;
}

h6 {
  text-align: left;
  padding-right: 10px;
  font-size: 14px !important;
  font-weight: $weight-semi;
  min-width: 140px;
}

.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.d-block {
  display: block;
}

.text-right {
  text-align: right;
}

.mb-sm-2 {
  margin-bottom: 1rem;
}

.d-md-block {
  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.d-sm-block {
  @media screen and (max-width: 567px) {
    display: block !important;
  }
}

.flex-md-wrap{
  @media screen and (min-width: 600px) {
    flex-wrap: wrap !important;
  }
}

/* Add Target Page CSS */
.target-card-inline-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 10px;
  margin: 10px 0 20px 0;
  padding-right: 30px;
  width: 100%;

  .input-section {
    flex: 1;

    // width: calc(33.33% - 14px);
    // min-width: 108px;
    label{
      display: inline-block;
      white-space: nowrap;
      min-width: 98px;
    }
    &.input-section-last {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      min-width: auto;
      padding-top: 28px;
    }

    .ant-form-item {
      margin: 0;
      padding: 0;
    }

    .ant-form-item-label {
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }
  }

  &.col-count-3 {
    .input-section {

      // width: calc(50% - 12px);
      &.input-section-last {
        width: 20px;
        padding-top: 28px;
      }
    }
  }
}

.my-target-header {
  .page-content-header-right {
    .form-control-ant > .ant-form-item-row{
      align-items: center;
    }
    .ant-form-item {
      margin-bottom: 0;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      text-align: right;
      .ant-space {

        // flex-direction: column;
        width: 100%;
        // align-items: flex-end;
        .ant-space-item{
          width: 100%;
        }
        .ant-form-item {
          .ant-form-item-label {
            padding-bottom: 2px;

            label {
              height: auto !important;
            }
          }
        }
      }
    }
  }
  .select-month-picker{
    .ant-row{
      gap: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    // flex-direction: row !important;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column !important;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 10px;

    .page-content-header-right {
      width: 100%;
      text-align: right;
    }
  }
}

.my-target-search {
  .ant-form-item {
    .ant-form-item-label {
      label {
        height: 38px !important;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .ant-btn {
      margin-top: 24px;
    }

    .ant-form-item {
      .ant-form-item-label {
        padding-bottom: 2px;

        label {
          height: auto !important;
        }
      }
    }
  }
}

.report-date-range{
  max-width: 288px;
}
/* Custom Date Range Picker */ 
.custom-range-picker {
  .ant-picker-input{
    input{
      text-align: center;
    }
  }
}

/* Cursor pointer */
.cursor-pointer{
  cursor: pointer !important;
}
.graph-cursor-pointer{
  svg{
    g{
      cursor: pointer !important;
    }
  }
}
.column-chart-cursor-pointer{
  svg{
    g{
      g{
        rect, circle{
          cursor: pointer !important;
        } 
        g{
          text{
            cursor: pointer !important;
          }  
        }
      }
    }
    defs + rect + g{
      cursor: pointer !important;
    }
  }
}

/* Remove arrows in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Remove arrows in Chrome, Safari, Edge, and Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.reports-download-button{
  box-shadow: none !important;
  min-height: 38px;
}

/* Additional styles to ensure consistency */
input[type='number'] {
  appearance: none;
  margin: 0;
}
.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
  margin-top: -18px;
}
.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-wrapper:only-child .ant-table{
  margin-block: -11px;
}
.ant-table-wrapper{
  .ant-table{
    border-radius: 8px;
    .ant-table-container{
      border-radius: 8px;
      table{
        border-radius: 8px;
      }
    }
  }
}
.user-table-data{
  .user-data{
    padding: 0;
  }
}