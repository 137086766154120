.page-header {
    background-color: $white;
    border-bottom: 1px solid $borders;
    // padding: 10px 30px;
    height: auto;

    &.fixed {
        position: sticky;
        top: 0;
        z-index: 1;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .header-left {
        border-right: 1px solid #e6edef;
        width: 225px;
        padding: 10px 30px;
        @media (max-width:767px) {
            padding: 10px 0px;
         }
    }

    .header-logo {
        min-width: 195px;

        img {
            max-width: 50px;
            width: 100%;
            cursor: pointer;
        }
    }

    .header-heading {
        font-weight: $weight-medium;
        font-size: 18px;
        text-align: left;
        color: $black;
        white-space: nowrap;
        line-height: 24px;
        cursor: pointer;
    }

    .welcome {
        h4{
            padding: 10px 30px;
            text-align: start;
            margin-bottom: 0;
        }
        @media (max-width:767px) {
           display: none; 
        }
    }

    .user-profile {
        border-left: 1px solid $borders;
        padding: 10px 30px;

        @media (max-width:767px) {
            padding: 10px 0px;
         }

        .profile-logo {
            margin-left: 10px;
        }

        .ant-space-item {
            font-weight: $weight-medium;
            font-size: 16px;
            color: #000;
            line-height: 22px;
            padding-left: 10px;

            &:hover {
                background-color: transparent;
            }
        }

        .header-drop {
            .anticon-down {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
        @media (max-width:767px) {
            border-left: none; 
         }
    }

    @media screen and (max-width: 767px) {
        & {
            padding: 10px 15px;

            .header-logo {
                min-width: 150px;
            }

            .user-profile {
                .ant-space-item {
                    &:first-child {
                        display: none;
                    }
                }
            }
        }
    }
}

.user-profile-header {
    .ant-dropdown-menu {
        .ant-dropdown-menu-item {
            .anticon-user {
                padding-top: 1px;
            }

            .anticon-logout {
                padding-top: 3px;
            }
        }
    }
}

/* notification-list */
.notification-list{
    .ant-list-items{
        .ant-list-item{
            border-radius: 4px;
            padding: 6px 15px;
            margin-bottom: 5px;
            cursor: pointer;
            border: 1px solid transparent;
            align-items: flex-start;
            .ant-list-item-meta-title{
                margin: 0;
                font-weight: 600;
            }
            .time{
                margin: 0;
                font-size: 12px;
            }
            &.read{
                background-color: #fafafa;
                border-color: #F2F2F2;
                .ant-list-item-meta-title{
                    margin: 0;
                    font-weight: 500;
                }
            }
            &.unread{
                background-color: #e6edef;
            }
            
        }
    }
}