.page-content-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px 0 15px 0;
    .title{
        font-size: 20px;
        font-weight: $weight-semi;
        margin: 0;
    }
    &-left{
        display: flex;
        align-items: center;
        gap: 25px;
        .header-left-extra{
            flex: 1;
        }
    }
    @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 15px;
        &-left{
            width: 100%;
        }
        &-right{
            width: 100%;
            text-align: right;
            display: block;
        }
    }
    @media screen and (max-width: 375px) {

        &-right{
            .ant-space-gap-col-middle {
                column-gap: 10px;
                align-items: flex-end;
                .secondary-button, .primary-button {
                    padding: 0.375rem 1rem;
                }
            }
        }
    }
}