.user-data {
    .ant-card{
        height: 100%;
        .ant-card-body{
            .user-data.view-quotation-data{
                padding: 0 !important;
            }
        }
    }
    .day-task, .lead-funel-type, .lead-funel-stage, .user-table-data{
    
    }
    .user-left{
        h5{
            font-size: 18px;
            font-weight: $weight-medium;
        }
    }
    .task-wrapper{
        .ant-card{
            background-color: $inputBg;
            p{
                font-size: 28px;
                font-weight: $weight-bold;
                color: $primary;
            }
            span{
                font-size: 14px;
                font-weight: $weight-medium;
                color: $black;
            }
        }
    }
}

.custom-table{
    overflow: auto;
    width: 100%;
    table{
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid $tableBorder;
        border-right: none;
        border-bottom: none;
        margin: 0;
        width: 100%;
        thead{ 
            tr{ 
                th{
                    background-color: #fafafa;
                    border-bottom: 1px solid $tableBorder;
                    border-right: 1px solid $tableBorder;
                    border-left: none;
                    font-size: 14px;
                    padding: 10px 14px !important;
                    min-height: 50px !important;
                    font-weight: 600;
                }
                &:first-child{
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    th{
                        &:first-child{
                            border-top-left-radius: 8px;
                        }
                        &:last-child{
                            border-top-right-radius: 8px;
                        }
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    border-bottom: 1px solid $tableBorder;
                    border-right: 1px solid $tableBorder;
                    border-left: none;
                    font-size: 14px;
                    padding: 10px 14px !important;
                    min-height: 50px !important;
                }
                &:last-child{
                    td{
                        &:first-child{
                            border-bottom-left-radius: 8px;
                        }
                        &:last-child{
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
                &.totals-row{
                    td{
                        font-weight: 500;
                    }
                }
            }
        }
        .second-head{
                padding: 10px 0 !important;
            .target-head{
                background-color: #F0A09A;
                color: #C2403F;
                padding: 10px !important;
            }
            .achieved-head{
                background-color: #C8DEC5;
                color: #3AB853;
                padding: 10px !important;
            }
        }
        &.view-target-table{
            
        }
    }
}
.ant-tabs-extra-content {
    display: none;
}